export default {
    nav: {
        title: 'DVC',
        home: 'Home',
    },

    home: {
        title: 'Welkom',
        introPart1:
            'Met betaalbaar huren biedt de stad kwaliteitsvolle huurwoningen aan in Antwerpen voor mensen die geen eigen woning hebben, die geen woning vinden op de private huurmarkt of geen recht hebben op een sociale huurwoning.',
        introPart2:
            'Stad Antwerpen heeft voor hen momenteel een 100-tal huurwoningen. In de toekomst breiden we dat aanbod uit. Zo kunnen meer mensen genieten van een kwaliteitsvolle, betaalbare huurwoning in onze stad.',
        introPart3: 'Doe hier je aanvraag voor betaalbaar huren.',
        faq: 'Veelgestelde vragen',
        others: 'Andere',
    },

    processDescription: {
        start: 'Schrijf je hier in',
        draft: {
            title: 'Inschrijving',
            description: 'Schrijf je in met de juiste persoons- en inkomensgegevens. Wees volledig!',
        },
        registered: {
            title: 'Controle en aanbod',
            description:
                'We controleren of je gegevens correct zijn. We geven voorrang aan éénoudergezinnen en starters (jonger dan 36 jaar).' +
                '\nWe zoeken een geschikte huurder voor elke huurwoning. Ben jij dat? Dan krijg je een uitnodiging om langs te gaan.',
        },
        matched: {
            title: 'Plaatsbezoek en toewijzing',
            description: 'Je bezoekt de woning. Was je bezoek succesvol? Dan mag je de woning huren.',
        },
    },

    logOut: 'Uitloggen',
    logIn: 'Inloggen',
};
