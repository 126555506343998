import { Grid } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../../shared';
import { useStatusRequest } from '../../../shared/hooks';

export const HomePage: FC = () => {
    const { t } = useTranslation();
    const { data } = useStatusRequest();

    useEffect(() => {
        document.title = t('pages.home');
    }, []);

    return (
        <Page>
            <Grid container component="main" spacing={3} sx={{ mb: 3 }}>
                <Grid item xs={12} lg={7}>
                    <div>{data?.status}</div>
                </Grid>
            </Grid>
        </Page>
    );
};
