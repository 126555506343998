import { useMediaQuery, useTheme } from '@mui/material';

export const breakpoint = (size: string): boolean => {
    const theme = useTheme();
    if (size === 'xs') {
        return useMediaQuery(theme.breakpoints.up('xs'));
    } else if (size === 'sm') {
        return useMediaQuery(theme.breakpoints.up('sm'));
    } else if (size === 'md') {
        return useMediaQuery(theme.breakpoints.up('md'));
    } else if (size === 'lg') {
        return useMediaQuery(theme.breakpoints.up('lg'));
    } else {
        return false;
    }
};
