import { Box, Link, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const AppTitle: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Typography
            variant="appTitle"
            component={Link}
            onClick={() => navigate('')}
            underline="none"
            sx={{ cursor: 'pointer' }}
        >
            <Box className="app-logo">
                <img src={window.auction.environment.theme.logo} alt={t('nav.title')} />
            </Box>
            {t('nav.title')}
        </Typography>
    );
};
