export default {
    pages: {
        home: 'Home | DVC',
        myRentalRequest: 'Mijn dossier | DVC',
        form: 'Formulier | DVC',
        login: 'Aanmelden | DVC',
    },
    shared: {
        somethingWentWrong: 'Er liep iets mis:',
        back: 'Terug',
        save: 'Bewaar',
        cancel: 'Annuleer',
        confirm: 'Bevestig',
        next: 'Volgende',
        previous: 'Vorige',
        form: {
            requiredField: '{{fieldName}} is verplicht',
            invalidFormat: '{{fieldName}} ongeldig formaat',
            fallbackError: 'Er ging iets mis',
            ageYoungerThan18: 'Leeftijd is jonger dan 18 jaar',
            ageOlderThan18: 'Leeftijd is ouder dan 18 jaar',
            maxLength: 'Maximum {{amount}} karakters toegestaan',
            moneyFieldGreaterThanZero: 'Het bedrag moet groter dan 0 zijn',
            requiredQuestion: 'Deze vraag is verplicht',
            arrayNotEmpty: 'Selecteer minstens 1 item',
            dateInThePast: 'Datum moet in het verleden liggen',
            ageGreaterThan: 'Leeftijd mag niet hoger zijn dan {{amount}} jaar',
            isExpectedDateInTheFuture: 'De verwachte datum mag niet in het verleden liggen.',
            amountSmallerThan: 'Aantal moet kleiner zijn dan {{amount}}',
            zipcodeIsInvalid: 'Een postcode bestaat uit 4 karakters.',
            phoneIsInvalid: 'Gelieve een geldig telefoonnummer in te vullen.',
        },
        property: 'Eigendom',
        workRelation: 'Werkband',
        studyRelation: 'Studeerband',
    },
    uploadFile: 'Sleep je document hierheen, of klik om te selecteren',
    uploadFailed: {
        GENERIC_ERROR: 'Er ging iets mis bij het uploaden van het bestand.',
        FILE_TOO_BIG: 'Het bestand mag niet groter zijn dan 4MB. Probeer opnieuw met een kleiner bestand.',
        WRONG_FILE_TYPE:
            'Het bestand heeft een type dat niet toegestaan is. De toegelaten types zijn PDF, JPEG en PNG.',
        FILE_TOO_BIG_AND_WRONG_FILE_TYPE:
            'Het bestand mag niet groter zijn dan 4MB en is niet van een toegelaten type. Probeer opnieuw met een kleiner bestand. De toegelaten types zijn PDF, JPEG en PNG.',
    },
    deleteAttachmentTitle: 'Bijlage verwijderen',
    deleteAttachmentDescription: 'Ben je zeker dat je deze bijlage wilt verwijderen?',

    edit: 'Bewerk',
    cancel: 'Annuleer',
    status: 'Huidige status',
    no: 'Nee',
    yes: 'Ja',
    unknown: 'Onbekend',
    email: 'Email',
    phone: 'Telefoonnr.',
    skipToMainContentText: 'Ga naar de hoofdinhoud',
    accessibility: {
        profileButton: 'Profiel knop',
        account: 'Gebruiker',
        generalInformation: ' Algemene informatie',
        privacyPolicy: 'Privacy verklaring',
        faq: 'Veelgestelde vragen',
    },
    delete: 'Verwijder',

    requiredInput: '* : antwoord verplicht',
};
