import { FC } from 'react';
import { Login, useGetUser } from '../../../auth';
import { Loading } from '../../../shared';
import { Header } from '../header/header.component';
import { Routes } from '../routes/routes.component';

export const Main: FC = () => {
    const { data, isLoading } = useGetUser();

    if (isLoading) {
        return <Loading fullscreen />;
    }

    if (!data?.success) {
        return <Login />;
    }

    return (
        <>
            <Header />
            <Routes />
        </>
    );
};
