export default {
    errors: {
        hasTaxBillForFullYear: 'Antwoord op van welk jaar is je laatste aanslagbiljet',
        jointTaxableIncome: 'Gezamelijk belastbaar inkomen',
        hasIndividualTaxableIncome: 'Antwoord vraag afzonderlijk belastbaar inkomen',
        individualTaxableIncome: 'Afzonderlijk belastbaar inkomen',
        monthlyTaxableIncome: 'Maandelijks belastbaar inkomen',
        socialSecurityNumber: 'Rijksregisternummer',
        TAX_BILL_DOCUMENT: 'Aanslagbiljet',
        MONTHLY_INCOME_DOCUMENT: 'Loonfiche',
        PREGNANCY_DOCUMENT: 'Zwangerschapsattest',
        CHILD_SUPPORT_DOCUMENT: 'Attest van kinderbijslag of groeipakket',
        expectedAmount: 'Aantal verwachte kinderen',
        expectedDate: 'Uitgerekende datum',
        RESIDENTIAL_HISTORY_DOCUMENT: 'Attest domicilie',
        rentalRequestError: 'Algemeen',
        DISABILITY_INCOME_DOCUMENT: 'Attest inkomensvervangende tegemoetkoming voor personen met een handicap',
        LIVING_WAGE_DOCUMENT: 'Attest leefloon',
        email: 'Email',
        livingWage: 'Leefloon',
        disabilityIncome: 'Inkomensvervangende tegemoetkoming',
        foreignIncome: 'Buitenlandse inkomsten',
        hasMonthlyTaxableIncome: 'Maandelijks belastbaar inkomen',
        hasLivingWage: 'Leefloon',
        hasForeignIncome: 'Buitenlandse inkomsten',
        hasDisabilityIncome: 'Inkomensvervangende tegemoetkoming',
        street: 'Straat',
        number: 'Nummer',
        dateOfBirth: 'Geboortedatum',
        financialData: 'Financieel',
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        city: 'Stad',
        zip: 'Postcode',
        country: 'Land',
        taxYear: 'Aanslagjaar',
        unknown_name: 'Onbekend',
        hasChildSupport: 'Kinderbijslag of groeipakket',
        hasDisabilityDocument: 'Antwoord vraag heeft attest invaliditeit',
        hasResidentialHistory: 'Antwoord vraag woonhistoriek',
        aResidentOwnsProperty: 'Antwoord vraag heeft woning',
        phone: 'Telefoonnummer',
        contactType: 'Contact type',
    },

    DUPLICATE_USE: 'Het {{property}} van deze persoon wordt al gebruikt in een ander dossier, zie',
    DUPLICATE_USE_IN_RENTAL_REQUEST: 'Het {{property}} van deze persoon wordt al gebruikt in dit dossier',
    EMPTY: '{{property}} ontbreekt nog in ',
    DOCUMENT_NOT_EXPECTED: 'Geen document verwacht.',
    TOO_LOW: '{{property}} is te laag in ',
    TOO_HIGH: '{{property}} is te hoog in ',
    INVALID_FORMAT: '{{property}} heeft geen geldig formaat in ',
    INVALID_LENGTH: '{{property}} heeft een ongeldige lengte in ',
    NOT_AN_ENUM_VALUE: '{{property}} is ongeldig in ',
    INVALID_DATE_FORMAT: '{{property}} is geen geldige datum in ',
    INVALID_STRING: '{{property}} heeft geen geldig formaat in  ',

    PRIMARY_RESIDENT_TOO_YOUNG: 'Hoofdaanvrager is onder de 18 jaar.',
    RESIDENT_HAS_PROPERTY: 'Eén van de bewoners heeft een eigendom.',
    RESIDENT_HAS_NO_RESIDENTIAL_HISTORY:
        'Je woont nog geen 3 jaar aaneensluitend in {{city}} tijdens de laatste 10 jaar.',
    REFERENCE_INCOME_TOO_HIGH: 'Het referentie inkomen is te hoog.',
    REFERENCE_INCOME_TOO_LOW: 'Het referentie inkomen is te laag.',
    RESIDENT_NOT_IN_REGISTER: 'Eén van de medebewoners zit niet in het bevolkingsregister.',
    TAX_YEAR_OR_INCOME_EMPTY: 'Zowel het referentie inkomen als het aanslagjaar moeten ingevuld zijn.',
    reason: 'Reden',
    asset: {
        EMPTY: '{{asset}} ontbreekt nog.',
    },
    formError: {
        DUPLICATE_USE: 'Dubbel gevonden in ander dossier',
        DUPLICATE_USE_IN_RENTAL_REQUEST: 'Dubbel in jouw dossier',
    },
    validationErrorText: 'Enkele gegevens zijn foutief',
    missingData: 'Enkele gegevens ontbreken',
    contactFaq: 'Is je financiële situatie sterk gewijzigd? In de <0>{{link}}</0> staat wat je kan doen in dit geval.',
    contactFaqLink: 'FAQ',
};
