import shared from './shared';
import main from './main';
import auth from './auth.nl';
import errors from './errors';

export default {
    translation: {
        ...main,
        ...shared,
        ...auth,
        ...errors,
    },
};
