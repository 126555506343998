import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CarityTheme, Main } from './main';

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const App: FC = () => {
    return (
        <BrowserRouter>
            <CarityTheme>
                <QueryClientProvider client={queryClient}>
                    <Main />
                </QueryClientProvider>
            </CarityTheme>
        </BrowserRouter>
    );
};
