import { Home } from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Box, Tab, Tabs } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Account } from '../account/account.component';
import { breakpoint, SkipLink } from '../../../shared';

export const Navigation: FC = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const selectedTab = useMemo(() => location.pathname.split('/')[1] || 'home', [location]);
    const mediumScreen = breakpoint('md');

    const navItems = [{ label: t('nav.home'), value: 'home', to: '/', icon: <Home /> }];

    return mediumScreen ? (
        <>
            <SkipLink skipToElement="main-content" />
            <Tabs indicatorColor="primary" textColor="primary" sx={{ flexGrow: 1 }} value={selectedTab}>
                {navItems.map(({ label, value, to }) => (
                    <Tab key={value} label={label} value={value} component={Link} to={to} />
                ))}
            </Tabs>
            <Account />
        </>
    ) : (
        <>
            <Box sx={{ flexGrow: 1 }} />
            <Account />
            <BottomNavigation showLabels value={selectedTab}>
                {navItems.map(({ label, value, to, icon }) => (
                    <BottomNavigationAction
                        key={value}
                        label={label}
                        value={value}
                        component={Link}
                        to={to}
                        icon={icon}
                    />
                ))}
            </BottomNavigation>
        </>
    );
};
