import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, MouseEventHandler, ReactNode, useMemo } from 'react';
import { Loading } from '../loading/loading.component';

interface Props {
    children: ReactNode;
    title?: ReactNode;
    filter?: ReactNode;
    subTitle?: ReactNode;
    actions?: ReactNode | ReactNode[];
    onBack?: MouseEventHandler;
    loading?: boolean;
}

export const Page: FC<Props> = ({ actions, onBack, title, subTitle, filter, children, loading }) => {
    const mappedActions = useMemo(() => (Array.isArray(actions) ? actions : [actions]), [actions]);

    return (
        <Box
            sx={{
                maxWidth: 1200,
                width: '90%',
                mb: '75px',
                ml: 'auto',
                mr: 'auto',
                pt: title ? {} : { xs: 5 },
                whiteSpace: 'pre-line',
            }}
        >
            {title && (
                <Box sx={{ mb: 2 }}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ height: 96 }}
                        flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                        display={{ xs: 'contents', md: 'flex' }}
                        rowSpacing={{ xs: 3, md: 0 }}
                    >
                        <Grid item>
                            <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
                                {onBack && (
                                    <Grid item>
                                        <IconButton
                                            color="inherit"
                                            aria-label="back button"
                                            onClick={onBack}
                                            size="large"
                                            sx={{ color: 'text.secondary' }}
                                        >
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item>
                                    <Typography variant="title" mb={title && 0}>
                                        {title}
                                    </Typography>

                                    {subTitle && (
                                        <Typography variant="subtitle2" color="text.secondary">
                                            {subTitle}
                                        </Typography>
                                    )}
                                </Grid>
                                {filter && <Grid item>{filter}</Grid>}
                            </Grid>
                        </Grid>
                        {mappedActions && (
                            <Grid item>
                                <Grid
                                    container
                                    flexWrap="wrap"
                                    alignItems="center"
                                    columnSpacing={{ xs: 0, sm: 1 }}
                                    rowSpacing={{ xs: 1, sm: 0 }}
                                    columns={{ xs: 1, sm: 2 }}
                                >
                                    {mappedActions.map((action, index) => (
                                        <Grid
                                            item
                                            key={index}
                                            sx={{
                                                flexGrow: 1,
                                                minWidth: { xs: '100% ', sm: 'auto' },
                                                '& > *': {
                                                    minWidth: { xs: '100% !important', sm: 'auto' },
                                                },
                                            }}
                                        >
                                            {action}
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Divider />
                </Box>
            )}
            <Box>{loading ? <Loading /> : children}</Box>
        </Box>
    );
};
