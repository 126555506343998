import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useGetUser } from '../../auth';
import { myStatusClient } from '../clients/status.client';
import { QueryKey } from '../enums';
import { IStatusRequest } from '../models';

export const useStatusRequest = (): UseQueryResult<IStatusRequest, AxiosError> => {
    const { data: user } = useGetUser();

    return useQuery([QueryKey.STATUS], () => myStatusClient.getStatus(), {
        enabled: user?.success,
    });
};
